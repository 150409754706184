import React, { useContext, useEffect } from 'react'

import { Divider, Grid } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { BrandContext } from '../../../../context/BrandContext/context'

import Content from './Content'
import Header from './Header'

const useStyles = makeStyles((theme) => { 
    
    console.log('useStyles | theme', theme)
    return {

    root: {
        width: '327px',
        [theme.breakpoints.down('sm')]: {
            width: '320px',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    headerRoot: {
        width: '100%',
        color: '#424242',
        fontSize: '16pt',
    },
    headerLine1: {
        fontWeight: 'bold',
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.90rem',
        },
    },
    layerTitle: {
        fontWeight: '800',
        fontSize: '0.90rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.85rem',
            fontWeight: '800',
        },
    },
    feasibilityItem: {
        borderTop: '1px #55555544 solid',
        marginTop: '5px',
        paddingTop: '4px',
    },
    contentRoot: {
        width: '100%',
    },
    listItem: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.03)',
        padding: '3pt',
    },
    list: {
        listStyleType: 'none',
        padding: '2px',
    },
    coverageText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
        },
    },
    hasCoverage: {
        color: theme.popup.icons.live.color.base,
        paddingRight: '2px',
        [theme.breakpoints.down('sm')]: {
            width: '0.85em',
        }
    },
    noCoverage: {
        color: theme.popup.icons.none.color.base,
        paddingRight: '2px',
        [theme.breakpoints.down('sm')]: {
            width: '0.85em',
        }
    },
    buttonCovered: {
        borderRadius: '4px',
        background: theme.popup.buttons.live.color.base,
        width: '131px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
        },
        '&:hover': {
            background: theme.popup.buttons.live.color.hover
        }
    },
    buttonNotCovered: {
        borderRadius: '4px',

        backgroundColor: theme.popup.buttons.contact.color.base,
        width: '131px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
        },
        '&:hover': {
            background: theme.popup.buttons.contact.color.hover
        }
    }
}}
)

function VerboseMarkerContent({ layers, coverage, place, heading, customURL }) {
    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Header place={place} useStyles={useStyles} customHeading={heading} />
            <Divider />
            <Content layers={layers} coverage={coverage} useStyles={useStyles} customURL={customURL}/>
        </Grid>
    )
}

export default VerboseMarkerContent
