import { SET_BRAND } from './types'

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_BRAND:
            return [...state, action.brand]
        default:
            return state
    }
}
