import { SET_DATA, SET_PROVIDERS, SET_SERVICES, SET_STATUSES, SET_BOUNDARIES , SET_CLIENTS} from './DataTypes'

export const DataReducer = (state, action) => {
    switch (action.type) {
        case SET_DATA:
            return { ...state, ...action.payload }
        case SET_BOUNDARIES:
            return { ...state, boundaries: action.payload  }
        case SET_PROVIDERS:
            return { ...state, providers: action.payload.providers }
        case SET_SERVICES:
            return { ...state, services: action.payload.services }
        case SET_STATUSES:
            return { ...state, statuses: action.payload.statuses }
        case SET_CLIENTS:
            return { ...state, clients: action.payload.clients }
        default:
            return state
    }
}
