import React from 'react'
import { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons';
// import Checkbox from '@mui/material/Checkbox';

function ListCatergoryAction(props) {

    const [checkedBox, setCheckedBox] = useState(true)

    const toggle = () => {

        const newValue = !checkedBox

        setCheckedBox(newValue)

        if(props.toggleCategory) props.toggleCategory(newValue, props.index)
    }

    return (
        <IconButton
            className={props.styles.actionButton}
            onClick={(e) => {
                toggle()
            }}
            id={props.category.category}
            >
            {   checkedBox ? 
                <Visibility/> : 
                <VisibilityOff />
            }
        </IconButton>
    )
}

export default ListCatergoryAction  