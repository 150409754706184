import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Content from '../components/Content'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { BrandContext } from '../context/BrandContext/context'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
}))

export default function PortalView() {
    const { brand } = useContext(BrandContext)
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {brand.navbar.show && <Navbar />}
            {brand.sidebar.show && <Sidebar />}
            <Content />
        </div>
    )
}
