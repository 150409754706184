import React, { createContext, useReducer } from 'react'

import { DataReducer } from './DataReducer'

export const DataContext = createContext()

const DataProvider = ({ children }) => {
    const [data, dispatchData] = useReducer(DataReducer, {
        loading: false,
        boundaryTypes: [{ title: 'Promotion', value: 'promotion' }, { title: 'Exclusion', value: 'exclusion' }],
        services: [],
        providers: [],
        statuses: [],
        subscription: {},
        boundaries: [],
        clients: [],
        environments: []
    })

    return <DataContext.Provider value={{ data, dispatchData }}>{children}</DataContext.Provider>
}

export default DataProvider
