import { SET_USER, SET_AUTHENTICATED, SET_ERROR, SET_USER_CLIENT_NAME } from './types'

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            return  { ...state, ...action.payload }
        case SET_USER_CLIENT_NAME:
            return {...state, details: {...state.details, clientName: action.payload}}
        case SET_AUTHENTICATED:
            return [state, action.payload]
        case SET_ERROR:
            return [state, action.payload]
        default:
            return state
    }
}
