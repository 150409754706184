import React, { useContext, useEffect, useState } from 'react'

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import StopIcon from '@material-ui/icons/Stop';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';

import Utils from '../../utils/Utils'
import { GithubPicker } from 'react-color';
import DynamicForm from './DynamicForm';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'absolute',
        zIndex: '1'
    },
    contentRoot: {
        width: '100%',
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        // margin: theme.spacing(1),
    },
    fullWidth: {
        width: '100%'
    },
}))

export default function DynamicFormDialog(props) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
                className={classes.modal}>
                <DialogContent>
                    <DynamicForm
                        title={props.title}
                        loading={props.loading}
                        schema={props.schema}
                        details={props.details}
                        open={props.open}
                        handleClose={props.handleClose}
                        handleSave={props.handleSave}
                        onError={props.onError} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
