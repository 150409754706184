import React, { useContext, useEffect, useState } from 'react'

import { Button, Menu, MenuItem, TextField } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import firebase from 'firebase'

import { BrandContext } from '../context/BrandContext/context'
import { UserContext } from '../context/UserContext/context'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataContext } from '../context/DataContext/DataContext'
import { SET_USER } from '../context/UserContext/types'
import { SET_DATA } from '../context/DataContext/DataTypes'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: theme.palette.primary.main,
    },
    title: {
        flexGrow: 1,
    },
    clientSelect: {
        // backgroundColor: '#fff',
        maxWidth: '300px',
    },
    clientNameTxt: {
        // backgroundColor: '#fff',

    },
    appBarSpacer: theme.mixins.toolbar,

    avatar: {
        margin: 10,
    },
    logo: {
        position: 'relative',
        padding: '0px',
        paddingRight: '10px',
        paddingBottom: '5px',
        zIndex: '4',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            height: '1px',
            right: '15px',
            width: 'calc(100% - 60px)',
        },
    },
    logoLink: {
        textTransform: 'uppercase',
        padding: '5px 0',
        display: 'block',
        fontSize: '16px',
        textAlign: 'left',
        fontWeight: '400',
        lineHeight: '30px',
        textDecoration: 'none',
        backgroundColor: 'transparent',
    },
    logoImageContainer: {
        height: '50px',
        display: 'flex',
        maxHeight: '50px',
        marginLeft: '5px',
        marginRight: '5px',
    },
    logoImage: {
        height: '50px',
    },
    navbarSelect: {
        color: theme.palette.primary.contrastText
    },
}))

export default function Navbar(props) {
    const { brand } = useContext(BrandContext)
    const { user, dispatchUser } = useContext(UserContext)
    const { data, dispatchData } = useContext(DataContext)
    const classes = useStyles()
    // const [user, setUser] = useState()
    const [anchorEl, setAnchorEl] = useState()

    useEffect(() => {
        // if (brand.authentication) setUser(firebase.auth().currentUser)
        console.log('!!!!!!!!!!! user state changed in navbar', user)
    }, [user])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSignOut = () => {
        if (brand.authentication) firebase.auth().signOut()
        if (props.handleSignOut) props.handleSignOut()
    }

    const handleLogin = () => {
        if (props.handleLogin) props.handleLogin()
    }


    const onClientChange = async (client) => {
        try {

            let newData = { ...data }
            data.boundaries = []
            dispatchData({
                type: SET_DATA,
                payload: newData
            })

            let newUser = { ...user }
            newUser.details.token = client.token
            newUser.details.selectedClient = client
            newUser.details.clientFullName = client.full_name

            dispatchUser({
                type: SET_USER,
                payload: newUser
            })


            // await getClientSubscriptions(client.token)
            // await getClientBoundaries(client.token)
        } catch (e) {
            console.error('!! Client change', e)
            //   onError("Could not Change Client")
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo}>
                        <a href="/" className={classes.logoLink}>
                            <div className={classes.logoImageContainer}>
                                <img src={brand.logo} alt="" className={classes.logoImage} />
                            </div>
                        </a>
                    </div>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {user?.details?.role === 'admin' ?
                            <Autocomplete
                                id="clients-dropdown"
                                className={classes.clientSelect}
                                value={user.details.selectedClient}
                                options={data.clients}
                                getOptionLabel={(option) => option.full_name}
                                renderInput={(params) => <TextField {...params} placeholder="Clients" />}
                                onChange={(event, newValue) => {
                                    onClientChange(newValue)
                                }}
                            />
                            : user?.details?.clientFullName ?? ""
                        }
                    </Typography>
                    {/* <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <Autocomplete
                            id="env-dropdown"
                            className={classes.clientSelect}
                            value={data.selectedEnvironment}
                            options={data.environments}
                            getOptionLabel={(option) => option.full_name}
                            renderInput={(params) => <TextField {...params} placeholder="Clients" />}
                            onChange={(event, newValue) => {
                                onClientChange(newValue)
                            }}
                        />
                    </Typography> */}
                    <span className={classes.toolbarButtons}>
                        {
                            user && user.details && user.details.email && (
                                <div>
                                    <Button
                                        color="inherit"
                                        aria-controls="profile-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        tile="Click to sign out"
                                    >
                                        <p>{user.details.email}</p>
                                    </Button>
                                    <Menu
                                        id="profile-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleSignOut} tile="Click to sign out">
                                            Sign out
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )
                            // : (
                            //     <Button color="inherit" edge="end" onClick={handleLogin}>Login</Button>
                            // )
                        }
                        {/* <Button color="inherit" edge="end" >Login</Button> */}
                    </span>
                </Toolbar>
            </AppBar>
        </div>
    )
}
