import React from 'react'

import ReactDOM from 'react-dom'

import './index.css'
import App from './App'
import BrandProvider from './context/BrandContext/context'
import SiteProvider from './context/SiteContext/context'
import reportWebVitals from './reportWebVitals'
import Analytics from './services/Analytics'
import UserProvider from './context/UserContext/context'
import DataProvider from './context/DataContext/DataContext'

ReactDOM.render(
    <React.StrictMode>
        <BrandProvider>
            <SiteProvider>
                <UserProvider>
                    <DataProvider>
                        <App />
                    </DataProvider>
                </UserProvider>
            </SiteProvider>
        </BrandProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(Analytics.Log)
