import Utils from '../utils/Utils'

const WmsOverlay = {}

WmsOverlay.GetGoogleMapTileOverlay = (googleMap, apikey, layers, styles, viewparams, options) => {
    if (!googleMap || !window.google || !window.google.maps) throw 'google library not initialised.'
    if (!layers || layers === '') throw 'Invalid Layer'

    const mapOptions = {
        tileSize:
            options && options.tileSize
                ? new window.google.maps.Size(options.tileSize.width, options.tileSize.height)
                : new window.google.maps.Size(256, 256),
        isPng: options ? options.isPng : false,
        opacity: options && options.opacity ? options.opacity : 0.6,
        maxZoom: options && options.maxZoom ? options.maxZoom : 21,
        minZoom: options && options.minZoom ? options.minZoom : 0,
    }

    return new window.google.maps.ImageMapType({
        getTileUrl(coord, zoom) {
            // let s = Math.pow(2, zoom)
            // latlng bounds of the 4 corners of the google tile
            // let bottom_left = googleMap.getProjection().fromPointToLatLng(
            //     new window.google.maps.Point(coord.x * 256 / s, (coord.y + 1) * 256 / s)) // bottom left / SW
            // let top_right = googleMap.getProjection().fromPointToLatLng(
            //     new window.google.maps.Point((coord.x + 1) * 256 / s, coord.y * 256 / s)) // top right / NE
            const vp = viewparams ? `&${viewparams}` : ''
            const bbox = WmsOverlay.getBBox(googleMap, coord, zoom, options && options.bboxm ? 'meters' : 'coords') // bottom_left.lng() + "," + bottom_left.lat() + "," + top_right.lng() + "," + top_right.lat()
            return (
                `${process.env.REACT_APP_28EAST_WMS_BASE_URL}?` +
                `services=${layers}&styles=${styles}&bbox=${bbox}&key=${apikey}${vp}`
            )
        },
        tileSize: mapOptions.tileSize,
        isPng: mapOptions.isPng,
        opacity: mapOptions.opacity,
        maxZoom: mapOptions.maxZoom,
        minZoom: mapOptions.minZoom,
        name: layers || 'layerName',
    })
}

WmsOverlay.GetGoogleMapWMSTileOverlay = (googleMap, url, apikey, layers, styles, sldBody, options) => {
    if (!googleMap || !window.google || !window.google.maps) throw 'google library not initialised.'
    if (!layers || layers === '') throw 'Invalid Layer'

    const mapOptions = {
        tileSize:
            options && options.tileSize
                ? new window.google.maps.Size(options.tileSize.width, options.tileSize.height)
                : new window.google.maps.Size(256, 256),
        isPng: options ? options.isPng : false,
        opacity: options && options.opacity ? options.opacity : 0.6,
        maxZoom: options && options.maxZoom ? options.maxZoom : 21,
        minZoom: options && options.minZoom ? options.minZoom : 0,
    }

    return new window.google.maps.ImageMapType({
        getTileUrl(coord, zoom) {
            // Bounding box coords for tile in WMS pre-1.3 format (x,y)
            const bbox = WmsOverlay.getBBox(googleMap, coord, zoom, 'meters')
            return (
                `${url}?SERVICE=WMS` +
                '&REQUEST=GetMap' +
                '&VERSION=1.1.1' +
                `&LAYERS=${layers}&STYLES=${styles}&FORMAT=image/png` +
                '&TRANSPARENT=TRUE' +
                '&TILED=TRUE' +
                '&SRS=EPSG:900913' +
                `&BBOX=${bbox}&WIDTH=256` +
                `&HEIGHT=256${sldBody ? `&SLD_BODY=${sldBody}` : ''}${apikey ? `&key=${apikey}` : ''}`
            )
        },
        tileSize: mapOptions.tileSize,
        isPng: mapOptions.isPng,
        opacity: mapOptions.opacity,
        maxZoom: mapOptions.maxZoom,
        minZoom: mapOptions.minZoom,
        name: layers || 'layerName',
    })
}

WmsOverlay.getBBox = (googleMap, coord, zoom, type) => {
    const s = Math.pow(2, zoom)
    const bottom_left = googleMap
        .getProjection()
        .fromPointToLatLng(new window.google.maps.Point((coord.x * 256) / s, ((coord.y + 1) * 256) / s)) // bottom left / SW
    const top_right = googleMap
        .getProjection()
        .fromPointToLatLng(new window.google.maps.Point(((coord.x + 1) * 256) / s, (coord.y * 256) / s)) // top right / NE

    if (type !== 'meters') return `${bottom_left.lng()},${bottom_left.lat()},${top_right.lng()},${top_right.lat()}`

    const bottom_left_m = Utils.reProject(bottom_left.lat(), bottom_left.lng())
    const top_right_m = Utils.reProject(top_right.lat(), top_right.lng())
    return `${bottom_left_m[0]},${bottom_left_m[1]},${top_right_m[0]},${top_right_m[1]}`
}

WmsOverlay.GetGoogleMapCoverageOverlay = (googleMap, gmpKey, displayLayers, displayStyles = '', viewparams) => {

    if (!googleMap || !window.google || !window.google.maps) throw 'google library not initialised.'

    return new window.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {

            let s = Math.pow(2, zoom)
            //latlng bounds of the 4 corners of the google tile
            let bottom_left = googleMap.getProjection().fromPointToLatLng(
                new google.maps.Point(coord.x * 256 / s, (coord.y + 1) * 256 / s)) // bottom left / SW
            let top_right = googleMap.getProjection().fromPointToLatLng(
                new google.maps.Point((coord.x + 1) * 256 / s, coord.y * 256 / s)) // top right / NE

            let layers = displayLayers
            let styles = displayStyles

            if(!layers) return ''

            const bbox = bottom_left.lng() + ',' + bottom_left.lat() + ',' + top_right.lng() + ',' + top_right.lat()
            return process.env.REACT_APP_BASE_URL + 'wms?' +
                'key='+gmpKey+'&' +
                'service=WMS&' +
                'version=1.1.0&' +
                'request=GetMap&' +
                'layers=' + layers + '&' +
                'styles=' + styles + '&' +
                'bbox=' + bbox + '&' +
                'width=256&' +
                'height=256&' +
                'srs=EPSG:3857&' +
                'format=image/png' +
                '&TRANSPARENT=true' +
                viewparams
        },
        tileSize: new google.maps.Size(256, 256),
        isPng: true,
        opacity: 0.6,
        maxZoom: 21,
        minZoom: 0,
        name: 'layerName'
    })
}

export default WmsOverlay
