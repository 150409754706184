import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Collapse, Divider, ListItemIcon, Menu, MenuItem, Paper, TextField , SvgIcon, Grid } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles , createTheme, ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ArrowDropDown, ArrowDropUp, CategoryRounded, Close, CloudUpload, ExpandLess, ExpandMore, Filter, FilterList, Group, GroupWork, MoreVert, Person, Search } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PaletteIcon from '@material-ui/icons/Palette'
import { Autocomplete } from '@material-ui/lab'
import { GithubPicker } from 'react-color'

import Checkbox from '@mui/material/Checkbox';
import ListCatergoryAction from '../ListCatergoryAction'


import { ReactComponent as CircleIcon } from '../../content/circleFill.svg'
import { ReactComponent as PolygonIcon } from '../../content/polygonFill.svg'
import { ReactComponent as SquareIcon } from '../../content/squareFill.svg'
import { UserContext } from '../../context/UserContext/context'

import FeaturesListItem from './FeaturesListItem'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: theme.spacing(1),
        height:'100%',
        overflowY: 'auto',
    },
    title: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        zIndex: 1000,
    },
    noItems: {
        textAlign: 'center',
        color: '#989898',
        padding: theme.spacing(2)
    },
    filterContainer: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'relative',
        zIndex: 1000,
    },
    filter: {
        margin: '0px',
        padding: '0px',
        paddingLeft: theme.spacing(),
    },
    filterIcon: {
        padding: theme.spacing()
    },
    grow: {
        flexGrow: 1
    },
    features: {
        overflowY: 'auto',
        height: '88%'
    },
    center: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    category: {
        backgroundColor: '#fbfbfb',
        borderTop: '1px solid #ededed',
        borderBottom: '1px solid #ededed'
    },
    categoryTitle: {
        fontWeight: 'bold'
    },
    actionButton: {
        position: 'initial',
        zIndex: 1,
        padding: '6px'
    },
}))

const muiTheme = createTheme({
    overrides: {
        MuiAccordionSummary: {
            root: {
              '&:hover:not(.Mui-disabled)': {
                cursor: 'default'
              },
              height: '40px !important',
              maxHeight: '40px',
              minHeight: '40px',
              '&$expanded': {
                height: '40px !important',
                maxHeight: '40px',
                minHeight: '40px',
              }
            }
        },
        MuiAccordionDetails: {
            root: {
                padding: '0px 16px 16px !important'
            }
        },
    }
})

export default function GroupFeaturesList(props) {
    const classes = useStyles()
    const { user } = useContext(UserContext)
    const [showFilter, setShowFilter] = useState(false)
    const [showClients, setShowClients] = useState(false)
    const [filter, setFilter] = useState('')
    const [details, setDetails] = useState(props.details ? props.details : [])
    const [categories, setCategories] = useState([])
    const [defaultCollapsed, setDefaultCollapsed] = useState(true)
    const [categoriesClosed, setCategoriesClosed] = useState([])
    const [moreAnchorEl, setMoreAnchorEl] = useState()
    const [selectedMenu, setSelectedMenu] = useState()

    
    useEffect(() => {
        setCategories(getGroupedItems())
    }, [details])

    useEffect(() => {
        setCategories(getGroupedItems())
        handleDefaultCollapse()
    }, [])

    useEffect(() => {
        let groups = getGroupedItems()
        setCategories([...groups])
        handleDefaultCollapse([...groups])
    }, [props.details])

    // useEffect(() => {
    //     console.log('CATEGORIES || ',categories)
    //     console.log('FEATURES || ',props.details)
    // },[categories])

    const toggleDisplayCategory = (newValue, categoryIndex) => {

        let updatedCategories = categories.map((category, index) => {
            if(index === categoryIndex) {
                category.items.map(item => {
                    item.setMap(newValue === true ? props.map : null)
                    
                    return item
                })

                return category
            }

            return category
        })

        setCategories([...updatedCategories])
    }

    const toggleDisplayFeature = (feature) => { 
        console.log('Toggle feature',feature)
    }

    useEffect(() => {
        if (props.selectedFeature && categoriesClosed.filter(c => c === props.selectedFeature.properties.details.category.value).length > 0)
            openCategoryCollapse(props.selectedFeature.properties.details.category.value)
    }, [props.selectedFeature])

    const updateDetailsFilter = () => {
        let tmp = []
        if (filter && filter.length > 0) {
            tmp = props.details.filter(d => d.properties.details.title.value.includes(filter))
        } else {
            tmp = props.details
        }
        setDetails(tmp)
    }

    const handleDefaultCollapse = (groups) => {
        if (!groups || groups.length === 0 || !defaultCollapsed) return
        let vals = []
        groups.forEach(c => {
            vals.push(c.category)
        })
        setCategoriesClosed(vals)
        setDefaultCollapsed(false)
        handleClose()
    }

    const onSelect = (value) => {
        if (props.onSelect) props.onSelect(value)
        onZoom(value)
        handleClose()
    }

    const onEdit = (value) => {
        if (props.onEdit) props.onEdit(value)
        handleClose()
    }

    const onDelete = (value) => {
        if (props.onDelete) props.onDelete(value)
        handleClose()
    }

    const onDeleteCategory = (value) => {
        if (props.onDeleteCategory) props.onDeleteCategory(value)
        handleClose()
    }

    const onZoom = (value) => {
        if (props.onZoom) props.onZoom(value)
        handleClose()
    }

    const onSave = (value) => {
        if (props.onSave) props.onSave(value)
        handleClose()
    }

    const onCancel = (value) => {
        if (props.onCancel) props.onCancel(value)
        handleClose()
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
        handleClose()
    }

    const handleClose = () => {
        setMoreAnchorEl(null)
        setSelectedMenu(null)
    }

    const getGroupedItems = () => {
        let items = props?.details ?? []
        let groups = []
        items.forEach(i => {
            let hasGroup = groups.filter(g => g.category === i.properties.details.category.value)
            if (hasGroup && hasGroup.length > 0)
                hasGroup[0].items.push(i)
            else {
                groups.push({
                    category: i.properties.details.category.value,
                    items: [i]
                })
            }
        })
        return groups
    }

    const handleCategoryCollapseClick = (category) => {
        let cc = [...categoriesClosed]
        let closedCategories = cc.filter(c => c === category)
        if (closedCategories.length === 0)
            cc.push(category)
        else {
            cc = cc.filter(c => c !== category)
        }
        setCategoriesClosed(cc)
    }

    const openCategoryCollapse = (category) => {
        let cc = [...categoriesClosed]
        cc = cc.filter(c => c !== category)
        setCategoriesClosed(cc)
    }

    

    return (
        <ThemeProvider theme={muiTheme}>
            <Accordion
                expanded={props.expanded}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore onClick={() => { props.handleCampaignFilterAccordion()}} />}
                >
                    <Grid container justifyContent='space-between' wrap='nowrap'>
                        <Typography style={{paddingTop: '8px'}}>Campaigns</Typography>
                        <Grid container item justifyContent='flex-end'>
                            <Grid item>
                                <IconButton
                                    className={classes.filterIcon}
                                    title='Import boundaries from a file'
                                    alt='Import boundaries from a file'
                                    onClick={(e) => {
                                        if (props.onUpload) props.onUpload()
                                    }}>
                                    <CloudUpload />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Divider />
                    </Grid>
                </AccordionSummary>
                <AccordionDetails
                    style={{maxHeight: '60vh', minHeight: '60vh', flexDirection: 'column'}}
                >
                <Divider />
                    <div className={classes.filterContainer}>
                        {/* <Typography variant="h6" className={classes.grow}>
                            {props.title ? props.title : 'Features'}
                        </Typography> */}
                        <TextField
                            variant="outlined"
                            color="default"
                            // className={classes.filter}
                            value={filter}
                            onChange={handleFilterChange}
                            key={'filter_txt'}
                            margin="dense"
                            id={'filter_txt'}
                            label={'Search Campaign'}
                            // placeholder={'Search Campaign'}
                            type="text"
                            fullWidth
                        />
                    </div>

                    <Grid className={classes.root} elevation={1}>
                        {!props.loading ?//|| props.details ?
                            <>{(props?.details && props.details.filter(d => { return !filter || filter.length === 0 ? true : d?.properties?.details?.name?.value?.toLowerCase().includes(filter.toLowerCase()) }).length > 0) ?
                                <div className={classes.features}>
                                    <List dense={true}>
                                        {categories.sort((a,b) => (a.category.toLowerCase() < b.category.toLowerCase()) ? -1 : (a.category.toLowerCase() > b.category.toLowerCase()) ? 1 : 0 ).map((category, categoryIndex) => {
                                            if (category.items.filter(d => { return !filter || filter.length === 0 ? true : d.properties.details.name.value.toLowerCase().includes(filter.toLowerCase()) }).length === 0) {
                                                return <></>
                                            }

                                            return (<>
                                                <ListItem
                                                    id={category.category}
                                                    button
                                                    className={classes.category}
                                                    onClick={(e) => handleCategoryCollapseClick(category.category)}
                                                > 
                                                    <ListItemIcon style={{ minWidth: '35px' }} >
                                                        <IconButton
                                                            className={classes.actionButton}
                                                            aria-label="expand"
                                                            onClick={(e) => handleCategoryCollapseClick(category.category)}
                                                        >
                                                            {categoriesClosed.filter(c => c === category.category).length > 0 ?
                                                                <ArrowDropUp />
                                                                : <ArrowDropDown />}
                                                        </IconButton>
                                                    </ListItemIcon>
                                                    <ListItemText primary={category.category + ` (${category.items?.length > 0 ? category.items?.length : '0'})`} />
                                                    <ListItemSecondaryAction style={{ right: '0px' }}>
                                                        <IconButton
                                                            className={classes.actionButton}
                                                            aria-label="Show More"
                                                            aria-controls={category.category}
                                                            aria-haspopup="true"
                                                            onClick={(e) => onDeleteCategory(category.category)}
                                                            >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                        <ListCatergoryAction styles={classes} category={category} toggleCategory={toggleDisplayCategory} index={categoryIndex} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Collapse in={categoriesClosed.filter(c => c === category.category).length === 0} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {category.items.sort((a,b) => (a.properties.details.name.value.toLowerCase() < b.properties.details.name.value.toLowerCase()) ? -1 : (a.properties.details.name.value.toLowerCase() > b.properties.details.name.value.toLowerCase()) ? 1 : 0 ).filter(d => { return !filter || filter.length === 0 ? true : d.properties.details.name.value.toLowerCase().includes(filter.toLowerCase()) }).map(feature => {
                                                            return <FeaturesListItem
                                                                key={feature.properties.id + '_item'}
                                                                details={feature}
                                                                selectedFeature={props.selectedFeature}
                                                                onSelect={onSelect}
                                                                onEdit={onEdit}
                                                                onDelete={onDelete}
                                                                onZoom={onZoom}
                                                                onSave={onSave}
                                                                onCancel={onCancel}
                                                                map={props.map}
                                                                toggleDisplay={toggleDisplayFeature}
                                                            />
                                                                
                                                        })}
                                                    </List>
                                                </Collapse> 
                                                </>)
                                        })}
                                    </List>
                                </div>
                                : <div className={classes.noItems}><Typography variant={'caption'}> No features</Typography></div>
                            }</> : <div className={classes.center}>
                                <CircularProgress />
                        </div>}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    )
}
