import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PaletteIcon from '@material-ui/icons/Palette';
import { ClickAwayListener, Divider, Grow, ListItemIcon, Menu, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { ReactComponent as PolygonIcon } from '../../content/polygonFill.svg'
import { ReactComponent as SquareIcon } from '../../content/squareFill.svg'
import { ReactComponent as CircleIcon } from '../../content/circleFill.svg'
import { SvgIcon } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { GithubPicker, TwitterPicker } from 'react-color';
import { Close, CloudUpload, Error, Fullscreen, MoreVert, Save, Visibility, VisibilityOff, Warning, ZoomIn } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: theme.spacing(1)
    },
    title: {
        margin: theme.spacing(1),
    },
    noItems: {
        textAlign: 'center',
        color: '#989898',
        padding: theme.spacing(2)
    },
    vectorIcons: {
        width: '20px',
        height: '20px'
    },
    palette: {
        position: 'absolute',
        marginTop: '-18px',
        marginLeft: '115px',
        zIndex: 1000
    },
    popup: {
        // position: 'absolute',
        // right: '100px',
        // top: '34px'
    },
    actionButton: {
        position: 'initial',
        zIndex: 1,
        padding: '1px'
    },
    error: {
        color: '#ff0000'
    },
    warning: {
        color: '#ff9d00'
    }
}))

export default function FeaturesListItem(props) {
    const classes = useStyles();
    const ref = useRef()
    const [color, setColor] = useState('#474747')
    const [paletteOpen, setPaletteOpen] = useState(false)
    const [selectedFeature, setSelectedItem] = useState()
    const [active, setActive] = useState(false)
    const [promote, setPromote] = useState(false)
    const [moreOpen, setMoreOpen] = useState(false)
    const [moreAnchorEl, setMoreAnchorEl] = useState()
    const [selectedMenu, setSelectedMenu] = useState(null)
    const [checkedBox, setCheckedBox] = useState(props.details.map ? true : false)


    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                closePalette()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref])

    useEffect(() => {

        if (props.details) setCheckedBox(props.details.map ? true : false)
    
    }, [props.details.map])
    

    const handleClose = () => {
        setMoreAnchorEl(null)
        setSelectedMenu(null)
    }

    const onSelect = (e, value) => {
        closePalette()
        if (props.onSelect) props.onSelect(value)
        handleClose()
    }

    const onEdit = (e, value) => {
        handleClose()
        if (props.onEdit) props.onEdit(value)
    }
    const onSave = (e, value) => {
        handleClose()
        if (props.onSave) props.onSave(value)
    }
    const onCancel = (e, value) => {
        handleClose()
        if (props.onCancel) props.onCancel(value)
    }

    // const onPromote = (e, value) => {
    //     handleClose()
    //     if (props.onPromote) props.onPromote(value)
    // }

    const onPalette = (e, value) => {
        setSelectedItem(value)
        if (!paletteOpen) setPaletteOpen(true)
        else setPaletteOpen(false)
    }

    const handleColorPicker = (value) => {
        if (props.details.properties.invalid) return
        setColor(value.hex)
        let t = selectedFeature
        selectedFeature.style.fillColor = value.hex
        selectedFeature.style.strokeColor = value.hex
        selectedFeature.set('fillColor', value.hex)
        // t.current.set('strokeWeight', 1)
        selectedFeature.set('strokeColor', value.hex)
        setSelectedItem(selectedFeature)
        closePalette()
        onSave(null, selectedFeature)
    }


    useEffect(() => {
        if (props.details.properties.details.active !== undefined && props.details.properties.details.active !== null)
            setActive(props.details.properties.details.active.value)
        // if (props.details.properties.details.promote !== undefined && props.details.properties.details.promote !== null)
        //     setPromote(props.details.properties.details.promote.value)
    }, [props.details])

    const closePalette = () => {
        setSelectedItem(null)
        setPaletteOpen(false)
    }

    const onDelete = (e, value) => {
        handleClose()
        if (props.onDelete) props.onDelete(value)
    }

    const onZoom = (e, value) => {
        handleClose()
        if (props.onZoom) props.onZoom(value)
    }

    const toggleDisplay = () => {
        const newValue = !checkedBox
        props.details.setMap(newValue === true ? props.map : null)

        setCheckedBox(newValue)

    }

    return (
        <>{props.details &&
            <div id={props.details.properties.id} className={classes.root}>
                <ListItem
                    button
                    key={'feature_item_' + props.details.properties.id}
                    selected={props.selectedFeature && props.selectedFeature.properties.id === props.details.properties.id}
                    onClick={(e) => onSelect(e, props.details)}
                >
                    <ListItemIcon style={{ minWidth: '35px' }}>
                        {props.details.properties.invalid &&
                            <Error className={classes.error} />
                        }
                        {(!props.details.properties.invalid && props.details.properties.editing) &&
                            <>
                                <Warning className={classes.warning} />
                            </>
                        }
                        {(!props.details.properties.invalid && !props.details.properties.editing) &&
                            <>
                                {props.details.properties.type.toLowerCase() === 'polygon' && <PolygonIcon className={classes.vectorIcons} fill={props.details.style ? props.details.style.fillColor : color} stroke={props.details.style ? props.details.style.strokeColor : color} />}
                                {props.details.properties.type.toLowerCase() === 'rectangle' && <SquareIcon className={classes.vectorIcons} fill={props.details.style ? props.details.style.fillColor : color} stroke={props.details.style ? props.details.style.strokeColor : color} />}
                                {props.details.properties.type.toLowerCase() === 'circle' && <CircleIcon className={classes.vectorIcons} fill={props.details.style ? props.details.style.fillColor : color} stroke={props.details.style ? props.details.style.strokeColor : color} />}
                            </>
                        }
                    </ListItemIcon>

                    <ListItemText
                        primary={props.details.properties.details.name.value}
                        // secondary={props.details.properties.details.description && props.details.properties.details.description.value ? props.details.properties.details.description.value : ''}
                        secondary={`Active: ${props.details.properties.details.active !== undefined && props.details.properties.details.active !== null ? props.details.properties.details.active.value + '' : 'false'}, Type: ${props.details.properties.details.type !== undefined && props.details.properties.details.type !== null ? props.details.properties.details.type.value + '' : ''} `}
                    />

                    <ListItemSecondaryAction style={{ right: '0px' }}>
                        {(!props.details.properties.invalid && !props.details.properties.editing) &&
                            <IconButton className={classes.actionButton} aria-label="color" onClick={(e) => onPalette(e, props.details)}>
                                <PaletteIcon />
                            </IconButton>}
                        {(!props.details.properties.invalid && props.details.properties.editing) &&
                            <IconButton className={classes.actionButton} edge="end" aria-label="save" onClick={(e) => onSave(e, props.details)}>
                                <Save />
                            </IconButton>}
                        {(props.details.properties.invalid || props.details.properties.editing) &&
                            <IconButton className={classes.actionButton} edge="end" aria-label="cancel" onClick={(e) => onCancel(e, props.details)}>
                                <Close />
                            </IconButton>}
                        {(!props.details.properties.invalid && !props.details.properties.editing) &&
                            <IconButton
                                className={classes.actionButton}
                                aria-label="more"
                                aria-controls={moreOpen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                    setSelectedMenu(props.details.properties.id)
                                    setMoreAnchorEl(e.currentTarget)
                                }}>
                                <MoreVert />
                            </IconButton>
                        }
                        <Menu
                            id={"feature-list-item-" + props.details.properties.id}
                            anchorEl={moreAnchorEl}
                            keepMounted
                            open={selectedMenu === props.details.properties.id}
                            onClose={handleClose}
                        >
                            {(!props.details.properties.invalid && !props.details.properties.editing) &&
                                <MenuItem onClick={(e) => onZoom(e, props.details)}>
                                    <ListItemIcon>
                                        <Fullscreen fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Zoom to Feature</Typography>
                                </MenuItem>}
                            {(!props.details.properties.invalid && !props.details.properties.editing) &&
                                <MenuItem onClick={(e) => onEdit(e, props.details)}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Edit</Typography>
                                </MenuItem>}
                            {(!props.details.properties.invalid && !props.details.properties.editing) &&
                                <MenuItem onClick={(e) => onDelete(e, props.details)}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Delete</Typography>
                                </MenuItem>}
                        </Menu>
                        <IconButton
                            className={classes.actionButton}
                            onClick={() => toggleDisplay()} 
                        >
                            {
                                checkedBox ? 
                                <Visibility /> : 
                                <VisibilityOff />
                            }
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {paletteOpen &&
                    <div className={classes.popup} onClick={closePalette}>
                        <div ref={ref} className={classes.palette} onClick={closePalette}>
                            <GithubPicker color={color} onChange={handleColorPicker} triangle='top-right' />
                        </div>
                    </div>}
            </div>
        }</>
    );
}
