import firebase from 'firebase/app'

const UserController = {}

UserController.AuthorizeUser = async (id) => {
    if (!firebase || !firebase.apps.length || !id) return false
    try {
        let firebaseApp = firebase.app()
        let db = firebaseApp.firestore()

        let ref = db.collection('users').doc(id)
        let doc = await ref.get()
        console.log('auth received')
        if (!doc.exists || !doc.data().active) {
            let err = { code: 403, message: "Unauthorised" }
            throw(err)
        }
        let auth = doc.data();

        let client = await UserController.GetClientById(auth.client)
        auth.clientFullName = client.full_name
        auth.selectedClient = client

        console.log(' auth success Dispatch')
        return auth
    } catch (err) {
        console.error(err)
    }
}


UserController.GetAllClients = async () => {
    if (!firebase || !firebase.apps.length) return false
    try {
        let firebaseApp = firebase.app()
        let db = firebaseApp.firestore()
        let clients = []
        let snap = await db.collection('clients').where('active', '==', true).get()

        snap.forEach((doc) => {
            console.log(doc.id, " => ", doc.data())
            clients.push({ id: doc.id, ...doc.data() })
        })

        return clients
    } catch (err) {
        console.error(err)
    }
}


UserController.GetClientById = async (id) => {
    if (!firebase || !firebase.apps.length) return false
    try {
        let firebaseApp = firebase.app()
        let db = firebaseApp.firestore()
        let clients = []
        let doc = await db.collection('clients').doc(id).get()
        if (!doc.exists || !doc.data().active) {
            return id
        }
        let data = doc.data()

        return data
    } catch (err) {
        console.error(err)
    }
}

export default UserController;