import './App.css'
import React, { useContext, useEffect, useState } from 'react'

import { ThemeProvider, createTheme, useTheme } from '@material-ui/core/styles'
import firebase from 'firebase/app'
import history from 'history/browser'
import { Router, Route, Switch } from 'react-router-dom'

import { BrandContext } from './context/BrandContext/context'
import LoginView from './views/LoginView'
import PortalView from './views/PortalView'
import 'firebase/auth'
import { UserContext } from './context/UserContext/context'
import { SET_USER, SET_USER_CLIENT_NAME } from './context/UserContext/types'
import { SET_BOUNDARIES, SET_DATA } from './context/DataContext/DataTypes'
import UserController from './controllers/UserController'
import { DataContext } from './context/DataContext/DataContext'
import DataController from './controllers/DataController'
import { Snackbar } from '@material-ui/core'
import Alert from './components/Alerts/Alert'
import { logAction } from './services/Logging'
// import { auth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function App() {
    const { brand } = useContext(BrandContext)
    const { user, dispatchUser } = useContext(UserContext)
    const { data, dispatchData } = useContext(DataContext)
    const firebaseApp =
        brand.authentication && !firebase.apps.length
            ? firebase.initializeApp(brand.firebaseConfig)
            : brand.authentication
                ? firebase.app()
                : null // firebase.initializeApp(brand.firebaseConfig);
    const firebaseAppAuth = brand.authentication ? firebaseApp.auth() : null
    const [error, setError] = useState(false)
    const theme = createTheme({
        Sidebar: {
            color: 'black',
            textDecoration: 'none !important',
        },
        SidebarItem: {
            textDecoration: 'none !important',
        },
        BrandContainer: {
            textAlign: 'center',
        },
        palette: {
            primary: brand.color.primary,
            secondary: brand.color.secondary,
            live: brand.wms && brand.wms.color && brand.wms.color.live ? brand.wms.color.live : brand.color.primary,
            error: brand.color.error,
            comingsoon:
                brand.wms && brand.wms.color && brand.wms.color.comingsoon
                    ? brand.wms.color.comingsoon
                    : brand.color.secondary,
        },
        popup: { ...brand.popup }

    })

    useEffect(() => {
        if (brand.authentication) {
            // console.log('!!!!!!!!!!! added listener')
            firebaseAppAuth.onAuthStateChanged(async function (firebaseUser) {
                let user
                let auth = await UserController.AuthorizeUser(firebaseUser ? firebaseUser.email : null)
                if (auth) {
                    user = {
                        ...auth,
                        ...firebaseUser,
                    }
                    const info = {
                            info : 'User login in',
                            data: {
                                client: user?.client,
                                clientFullName: user?.clientFullName,
                                displayName: user?.displayName,
                                email: user?.email,
                                fullName: user?.full_name,
                                metadata: user?.metadata
                            }
                        }
                    logAction(info.data, 'usermanagement.login', 'User successfully logged in', info)
                }
                

                dispatchUser({
                    type: SET_USER,
                    payload: {
                        isAuthenticated: user && user.active ? true : false,
                        isAuthorized: user && user.active ? true : false,
                        isVerifying: false,
                        details: user,
                        error: firebaseUser && (!user || !user.active) ? 'Unauthorized' : null
                    }
                })
            })
        }
    }, [])

    useEffect(async () => {
        if (user.isAuthorized && user.isAuthenticated && user.details && user.details.token) {
            let newData = {}
            newData.boundaries = await getClientBoundaries()
            let s = await getClientSubscriptions()
            if (!data.clients || data.clients.length === 0)
                newData.clients = await getClients()
            else newData.clients = data.clients

            newData.providers = s?.providers ?? []
            newData.services = s?.services ?? []
            newData.statuses = s?.statuses ?? []
            newData.subscription = s?.subscription ?? []
            newData.environments = []

            if(process.env.REACT_APP_28EAST_API_URL_LOCAL) {
                newData.environments.push({
                    title: 'Local',
                    value: 'LOCAL',
                    url: process.env.REACT_APP_28EAST_API_URL_LOCAL
                })
            }
            if(user?.details?.role === 'admin') {
                newData.environments.push({
                    title: 'Development',
                    value: 'DEV',
                    url: process.env.REACT_APP_28EAST_API_URL_DEV
                })
            }

            newData.environments.push(...[
                {
                    title: 'Staging',
                    value: 'STG',
                    url: process.env.REACT_APP_28EAST_API_URL_STG
                },{
                    title: 'Production',
                    value: 'PROD',
                    url: process.env.REACT_APP_28EAST_API_URL_PROD
                }
            ])

            dispatchData({
                type: SET_DATA,
                payload: newData
            })
        }
    }, [user])

    useEffect(() => {
        console.log('!!!!!!!!!!!!!!! context DATA updated', data)

    }, [data])

    const getClientSubscriptions = async () => {
        if (!user.details.token) return
        setError(false)
        // dispatchData({
        //     type: SET_DATA,
        //     payload: { ...data, loading: true }
        // })
        let subscriptionResponse = await DataController.GetSubscriptions(user.details.token)
        console.log('!!!!!!!!!!!!!!!!! subs response', subscriptionResponse)
        if (subscriptionResponse && subscriptionResponse.success) {
            let parsedData = DataController.ParseSubscriptions(subscriptionResponse.service_subscriptions)
            return parsedData
            // dispatchData({
            //     type: SET_DATA,
            //     payload: parsedData
            // })

        } else {
            setError(subscriptionResponse.errorReason)
        }
    }

    const getClients = async () => {
        if (user.details.role !== 'admin') return
        // dispatchData({
        //     type: SET_DATA,
        //     payload: { ...data, loading: true }
        // })
        let clientsResponse = await UserController.GetAllClients()
        console.log('!!!!!!!!!!!!!!!!! clients response', clientsResponse)
        if (clientsResponse && clientsResponse.length > 0) {
            return clientsResponse
            // let parsedData = {
            //     ...data,
            //     clients: clientsResponse
            // }
            // dispatchData({
            //     type: SET_DATA,
            //     payload: parsedData
            // })

        }
    }

    const getClientBoundaries = async () => {
        if (!user.details.token) return
        setError(false)
        let value = user.details.clientName ?? user.details.token
        let clientBoundaries = await DataController.GetClientBoundaries(value)
        console.log('!!!!!!!!!!!!!!!!! clientBoundaries', clientBoundaries)
        if (clientBoundaries && clientBoundaries.length > 0) {
            // let parsedData = DataController.ParseFeatureCollection(boundariesResponse.data)
            // dispatchData({
            //     type: SET_BOUNDARIES,
            //     payload: clientBoundaries
            // })
            return clientBoundaries
        }else {
            setError(clientBoundaries.message)
        }
    }

    const handleErrorClose = (e) => {
        setError(false)
    }

    console.log('Core App | theme', theme)

    return (
        <ThemeProvider theme={theme}>
            {!brand.authentication || (user && user.isAuthenticated && user.isAuthorized) ? (
                <Router history={history}>
                    <Switch>
                        <Route path="/" theme={theme} component={PortalView} key="PortalView" />
                    </Switch>
                </Router>
            ) : (
                <LoginView />
            )}
            <Snackbar open={error} autoHideDuration={2000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleErrorClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default App
