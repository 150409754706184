import React, { Fragment, useContext, useEffect, useState } from 'react'

import { Checkbox, Chip, List, ListItem, ListItemIcon, ListSubheader, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { GridFilterListIcon } from '@mui/x-data-grid'
import { UserContext } from '../../context/UserContext/context'

const icon = < CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = < CheckBoxIcon fontSize="small" />

// const filterOptions = createFilterOptions=({
function Filter({ data, onServiceChange, onProviderChange, onStatusChange, selectedService}) {

  const [providers, setProviders] = useState([])

  const { user, dispatchUser } = useContext(UserContext)

  const [selectedClient, setSelectedClient] = useState(user?.details.selectedClient?.id)

  const [serviceKey, setServiceKey] = useState(true)
  const [providerKey, setProviderKey] = useState(true)
  const [statusKey, setStatusKey] = useState(true)

  useEffect(() => {

    if(user?.details?.selectedClient?.id !== selectedClient) {
      setProviderKey(!providerKey)
      setServiceKey(!serviceKey)
      setStatusKey(!statusKey)
      setSelectedClient(user?.details?.selectedClient?.id)
      setProviders([])
    }
  }, [user])

  useEffect(() => {
    setProviderKey(!providerKey)
    setStatusKey(!statusKey)
  },[selectedService])

  const prepareProviders = (value) => {

    if(!value?.value) {
      setProviders([])
      return
    }

    const newProviders = []

    const dataObject = data.subscription[value.value]

    const dataObjectKeys = Object.keys(dataObject)

    dataObjectKeys.forEach((key) => {

      if(dataObject[key]?.provider_full_name) {
        newProviders.push({
          title: dataObject[key].provider_full_name,
          value: dataObject[key].provider_name
        })
      }

    })

    setProviders(newProviders || [])
  }

  return (
    <List subheader={
      <ListSubheader disableSticky component="div" id="nested-list-subheader">
        {'Filter'}
      </ListSubheader>
    }>
      <ListItem 
        key={'service'}
        role={undefined}
      >
        <ListItemIcon>
          <FilterListIcon />
        </ListItemIcon>
        <Autocomplete id='field1'
          key={serviceKey}
          autoHighlight
          loading={data?.loading}
          options={data?.services}
          getOptionLabel={(option, index) => option && option.title }
          style={{
            width: '100%'
          }}
          onChange={(event, value) => {
            prepareProviders(value)
            onServiceChange(value)
          }}
          clearOnEscape
          filterSelectedOptions
          fullWidth
          renderInput={
            params => (
              <TextField 
                {...params}
                placeholder='Services'
                fullWidth
              />
            )
          }
        />
      </ListItem>
      <ListItem 
        key={'provider'}
        role={undefined}
        dense 
      >
        <ListItemIcon >
          <FilterListIcon />
        </ListItemIcon>
        <Autocomplete id="field2"
          key={providerKey}
          multiple size="small"
          loading={data.loading}
          options={providers.length ? providers : data.providers}
          getOptionLabel={
            (option) => option && option.title ? option.title : option
          }
          style={
            { width: '100%' }
          }
          onChange={(event, value) => onProviderChange(value)}
          clearOnEscape
          // disableCloseOnSelect
          renderTags={(value, getTagProps) =>
              // <Chip label={'Filtered'} className={styles.chip} />
            value.map((option, index) => (
              <Chip key={`provider-chip_${index}`} variant="outlined" label={option.title} {...getTagProps({ index })} />
            ))
          }
          renderOption={(option, { selected }) => (
            <React.Fragment >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </React.Fragment >
          )}
          renderInput={
            params => (<TextField
              {...params}
              placeholder="Providers"
              fullWidth />
        )} />
      </ListItem >
      <ListItem
        key='status'
        role={undefined}
      >
        <ListItemIcon>
          <FilterListIcon />
        </ListItemIcon>
        <Autocomplete
          key={statusKey}
          id='field3'
          loading={data?.loading}
          options={data?.statuses}
          getOptionLabel={(option, index ) => option && option?.title ? option.title : `placeholder-${index}`}
          style={
            { width: '100%' }
          }
          fullWidth
          onChange={(event, value) => onStatusChange(value)}
          clearOnEscape
          filterSelectedOptions
          renderInput={
            params => (
              <TextField {...params}
                placeholder="Statuses"
                fullWidth />
            )
          }
        />
      </ListItem>
    </List>
  )
}

export default Filter