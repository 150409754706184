import React, { useContext, useEffect, useRef, useState } from 'react'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert';
// import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from '@mui/x-data-grid';


import { BrandContext } from '../context/BrandContext/context'
import Utils from '../utils/Utils'

const useStyles = makeStyles((theme) => ({
    uploadBtn: {
        // position: 'absolute',
        right: '10pt',
        bottom: '18pt',
    },
    important: {
        color: theme.palette.secondary.dark,
        fontWeight: 'bold'
    },
    invalid: {
        backgroundColor: '#ed0202'
    }
}))

function Alert(props) {
    return <MuiAlert elevation={1} variant="filled" {...props} />;
}

export default function SpatialFileUpload(props) {
    const classes = useStyles()

    const [loading, setLoading] = useState(props.loading || false)
    const [file, setFile] = useState()
    const fileRef = useRef()

    const [uploadStartOpen, setUploadStartOpen] = useState(false)
    const [uploadOpen, setUploadOpen] = useState(false)
    const [validationOpen, setValidationOpen] = useState(false)
    const [error, setError] = useState(false)
    const [features, setFeatures] = useState([])
    const [rows, setRows] = useState([])

    const columns = [
        {
            field: 'name',
            headerName: 'name',
            editable: false,
            minWidth: 150
        },
        {
            field: 'type',
            headerName: 'Type',
            editable: false,
            minWidth: 150
        },
        {
            field: 'message',
            headerName: 'Message',
            editable: false,
            width: 400
        }
    ]

    useEffect(() => {
        parseFeaturesToRows()
    }, [features])


    const parseFeaturesToRows = () => {
        if (!features || features.length === 0) return

        // let attr = {}

        // if (props.defaultSchema) {
        //     Object.keys(props.defaultSchema).forEach(k => {
        //         let obj = props.defaultSchema[k]
        //         if (obj.required) attr[k] = obj.value
        //     })
        // }

        let newRows = features.map(feature => {
            if (feature.invalid) return {
                ...feature
            }
            return {
                ...feature.properties,
                message: 'Feature is valid'
            }
        }).sort((a, b) => b.invalid - a.invalid)

        setRows(newRows)
    }

    const onChangeFile = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const updatedJSON = e.target.files[0];
            setFile(updatedJSON)
        }
    }

    const handleFileUploadProcess = () => {
        setLoading(true)

        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = e => {
            try {
                const target = e.target
                const result = target?.result

                setFeatures(handleGeojson(Utils.toJson(result)))

                setUploadOpen(false)
                setValidationOpen(true)
            } catch (e) {
                console.error('Error occurred while reading file', e)
                if (e.message) setError(e.message)
            } finally {
                setLoading(false)
            }
        }
    }

    const handleGeojson = (geojson) => {
        if (!geojson || !geojson.type) throw { code: 400, message: 'Bad Request - Invalid geojson' }
        if (geojson.type.toLowerCase() !== 'feature' && geojson.type.toLowerCase() !== 'featurecollection') throw { code: 400, message: 'Bad Request - Invalid feature' }

        let features = []
        if (geojson.type === 'FeatureCollection')
            features = Utils.ParseFeatureCollection(geojson)
        else if (geojson.type === 'Feature')
            features = [Utils.ParseFeature(geojson)]

        return features.map(feature => {
            let invalidFeature = false
            if (!Utils.validateGeojson(feature)) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Invalid Feature - Unsupported geojson',
                    name: feature.properties?.name || 'No Name'
                }
            } else if (!Utils.validFeature(feature)) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Invalid Feature  - Self intersecting feature',
                    name: feature.properties?.name || 'No Name'
                }
            } else if (!feature.properties) {
                invalidFeature = true
                feature = {
                    id: feature.properties.id,
                    invalid: true,
                    message: 'Invalid Feature  - No properties',
                    name: feature.properties?.name || 'No Name'
                }
            }

            if (props.defaultSchema && !invalidFeature) {
                //validate required fields
                // for (let k of Object.keys(props.defaultSchema)) {
                //     let obj = props.defaultSchema[k]
                //     if (obj.required && !feature.properties[k]) {
                //         invalidFeature = true
                //         feature = {
                //             id: feature.properties.id,
                //             invalid: true,
                //             message: `Feature is missing required field "${k}"`,
                //             name: feature.properties?.name || 'No Name'
                //         }
                //         break;
                //     }
                // }
                if (!feature.properties.name) {
                    invalidFeature = true
                    feature = {
                        id: feature.properties.id,
                        invalid: true,
                        message: `Feature is missing required field "name"`,
                        name: feature.properties?.name || 'No Name'
                    }
                }

                if (!invalidFeature) {
                    feature.properties.details = JSON.parse(JSON.stringify(props.defaultSchema))
                    feature.properties.schema = JSON.parse(JSON.stringify(props.defaultSchema))
                    feature.properties.details.name.value = feature.properties.name || ""
                    feature.properties.attributes = feature.properties
                }
            }

            if (props.defaultGeomStyling && !invalidFeature) feature.style = { ...props.defaultGeomStyling }

            return feature
        })

    }

    const onSave = () => {

    }

    return (
        <div className={classes.root}>
            {/* <Button variant="contained" component="label">
                Upload
                <input hidden accept="image/*" multiple type="file" />
            </Button> */}

            <Button variant="contained" color="primary" className={classes.uploadBtn} onClick={(e) => setUploadStartOpen(true)}>
                Upload
            </Button>

            {/* Start File Upload Process Modal*/}
            <Dialog
                loading={loading}
                open={uploadStartOpen}
                onClose={() => { setUploadStartOpen(false) }}
                aria-labelledby="upload-dialog-title"
                aria-describedby="upload-dialog-description"
            >
                <DialogTitle id="upload-dialog-title">Import boundaries</DialogTitle>
                <DialogContent>
                    <DialogContentText id="upload-dialog-description">
                        <>
                            Before you start to upload boundaries into the system there are a few things to take note of <br />
                            <br />
                            - Each feature has to have the following attributes: <b className={classes.important}>A unique name </b>  and a  <b className={classes.important}>boundary type</b>  (exclusion, promotion, etc.)<br />
                            - All features will have the <b className={classes.important}>same attributes</b> provided <br />
                            - We only make use of <b className={classes.important}>polygon</b> feature types <br />
                            - Any invalid feature (eg. Self-intercepting polygon) <b className={classes.important}>will be omitted</b> <br />
                        </>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setUploadStartOpen(false) }} disabled={loading} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => { setUploadStartOpen(false); setUploadOpen(true) }} disabled={loading} color="secondary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Select file and upload modal */}
            <Dialog
                loading={loading}
                open={uploadOpen}
                onClose={() => { setUploadOpen(false) }}
                aria-labelledby="upload-dialog-title"
                aria-describedby="upload-dialog-description"
            >
                <DialogTitle id="upload-dialog-title">Select boundaries file </DialogTitle>
                <DialogContent>
                    <DialogContentText id="upload-dialog-description">
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button variant='outlined' color="secondary" component="label">
                                    Select file
                                    <input
                                        hidden
                                        accept="application/geo+json"
                                        multiple={false}
                                        type="file"
                                        ref={fileRef}
                                        onChange={onChangeFile} />
                                </Button>
                            </Grid>
                            <Grid item>
                                {file && <Typography variant='caption' style={{ paddingLeft: '10pt' }}>Selected File: <b>{file.name}</b> </Typography>}
                            </Grid>
                        </Grid>

                        <br />
                        <>
                            <b className={classes.important}>Please Note </b>
                            <br />
                            We only accept the following file formats: <b className={classes.important}>geojson </b>
                        </>
                        {error && <Alert severity="error">{error}</Alert>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setUploadOpen(false) }} disabled={loading} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => handleFileUploadProcess()} disabled={loading} color="secondary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* select file and upload modal */}
            <Dialog
                loading={loading}
                open={validationOpen}
                onClose={() => { setValidationOpen(false) }}
                aria-labelledby="upload-dialog-title"
                aria-describedby="upload-dialog-description"
            >
                <DialogTitle id="upload-dialog-title">Validating boundaries </DialogTitle>
                <DialogContent>
                    <DialogContentText id="upload-dialog-description">
                        {file &&
                            <>
                                Here are all the features loaded from the file <b>{file.name} </b> <br />
                                <br />
                            </>}
                    </DialogContentText>
                    {/* <div style={{ height: 400, width: '100%' }}> */}
                    <Box>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={20}
                            autoHeight
                            checkboxSelection
                            disableSelectionOnClick
                            density={'compact'}
                            getRowClassName={(params) => { params.invalidFeature ? 'invalid' : 'valid' }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setValidationOpen(false) }} disabled={loading} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => onSave()} disabled={loading} color="secondary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
